import _ from 'lodash';
/**
 * カンマ付きの文字列に変換
 * @param {Number} number
 * @param {locale} string
 * @param {option} object
 */
export const formattingNumber = (number, locale = 'ja-JP', option) => {
  if (_.isNumber(number)) {
   return number.toLocaleString(locale, option);
  }
  return 'Invalid number';
};
