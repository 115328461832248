<template>
  <el-cascader :value="section" :placeholder="placeholder" :key="Math.random()" :options="options" @change="handleChange" clearable :filter-method="filter" filterable></el-cascader>
</template>

<script>
import { normalize } from '@/utils/searchUtil';

export default {
  name: 'TwMcuPicker',
  props: {
    section: Array,
    placeholder: String,
    options: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('input', value);
    },
    filter(node, keyword) {
      const keywords = node.pathNodes.reduce((ary, pathNode) => {
        if (Array.isArray(pathNode.data.keywords)) {
          return ary.concat(node.pathLabels).concat(pathNode.data.keywords);
        }
        return ary.concat(node.pathLabels);
      }, []);
      return keywords.some((str) => normalize(str).includes(normalize(keyword)));
    },
  },
};
</script>

<style lang="scss" scoped>
.el-cascader {
  width: 400px;
}
</style>

<style lang="scss">
.el-cascader-menu__wrap {
  height: 236px;
}
.el-cascader__suggestion-list {
  max-height: 320px;
}
</style>
