<template>
  <el-table class="tw_table table_body_overflow_x_hidden" :max-height="height || 540" :data="items" :header-cell-class-name="sortBackground" :row-key="rowKey" :row-class-name="isDisabled" @header-click="sortChange" @selection-change="selectionChange" @row-click="rowClick" @row-dblclick="rowDbClick" @cell-click="cellClick" @row-contextmenu="onRowContextmenu">
    <el-table-column
      v-if="processTable"
      :class-name="'line'"
      width="60">
      <template slot-scope="props">
        <div :class="{curve: props.row.entityName, no_line: props.row.noLine}" />
      </template>
    </el-table-column>
    <el-table-column
      v-if="selection"
      type="selection"
      :reserve-selection="true"
      :fixed="false"
      width="70"
      align="center">
    </el-table-column>
    <template v-for="(schema, index) in schemas">
      <el-table-column
        v-if="schema.dataType === 'Customer'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :class-name="'preview_customer'"
        show-overflow-tooltip
      >
        <template slot-scope="props">
          <template v-if="props.row.visibleFlg === VISIBLE_FLG.OFF">
            <span class="customerName masked" style="display:inline-flex;align-items:center" :content="maskStr"><figure class="avatar table"><img src="/images/customer.png"></figure></span>
          </template>
          <template v-else>
            <div v-if="getCustomerLongName(props.row, schema.key)" style="opacity: 0;height: 0;min-width: calc(100% + 16px);">{{getCustomerLongName(props.row, schema.key)}}</div>
            <span class="customerName" style="display:flex;align-items:center" :content="getCustomerName(props.row, schema.key)"><figure class="avatar table"><img :src="getCustomerIcon(props.row, schema.key)"></figure></span>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'User'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        show-overflow-tooltip
      >
        <template slot-scope="props">
          <figure class="avatar table" style="display: inline-block;vertical-align: middle;"><img :src="getUserIcon(props.row, schema.key)"></figure><span style="display: inline;vertical-align: middle;">{{getUserName(props.row, schema.key)}}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'EventCancel'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
      >
        <template slot-scope="props">
          <tw-status-label v-if="props.row[schema.key] === PROCESS_CANCEL_FLG.ON" statusName="DELETE" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'StatusBar'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
      >
        <template slot-scope="props">
          <tw-status-bar :statusCode="props.row[schema.key]" :approval="props.row.processId ? getProgressApproval(props.row.processId) : true" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'StatusIcon'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
      >
        <template slot-scope="props">
          <tw-status-icon v-if="props.row[schema.key] || props.row[schema.key] === 0" :statusCode="props.row[schema.key]" :approval="true" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'EntityIcon'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        show-overflow-tooltip
      >
        <template slot-scope="props">
          <!-- <div style="opacity: 0;height: 0;min-width: calc(100% + 16px);">{{getEntityNameByProcessId(props.row[schema.key])}}</div> -->
          <tw-entity-icon v-if="props.row[schema.key]" :popover="true" :entityName="getParent(props.row[schema.key])" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'EntityIcon2'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        show-overflow-tooltip
      >
        <template slot-scope="props" v-if="props.row.entityName">
          <!-- <div style="opacity: 0;height: 0;min-width: calc(100% + 16px);">{{getEntityName(props.row[schema.key])}}</div> -->
          <tw-entity-icon v-if="props.row[schema.key]" :popover="true" :entityName="getEntityId(props.row[schema.key])" />
        </template>
      </el-table-column>
       <el-table-column
        v-else-if="schema.dataType === 'DeleteEntity'"
        :key="schema.key"
        prop="DeleteEntity"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
      >
        <template slot-scope="props">
          <img v-if="props.row.entityName" class="close_times" src="@/assets/images/icons/times.svg">
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'AlertIcon'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
      >
        <template slot-scope="props">
          <tw-alert-icon :limit="props.row[schema.key]" :processProgressStatus="props.row['processProgressStatus'] || props.row['processProgressStatusCd']" type="line" />
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'SelectCustomer'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        class-name="no_padding input select_customer"
        show-overflow-tooltip
      >
        <template slot-scope="props">
          <template v-if="props.row.visibleFlg === VISIBLE_FLG.OFF">
            <span class="customerName masked" style="display:inline-flex;align-items:center" :content="maskStr"><figure class="avatar table"><img src="/images/customer.png"></figure></span>
          </template>
          <template v-else>
            <div v-if="getCustomerLongName(props.row, schema.key)" style="opacity: 0;height: 0;min-width: calc(100% + 16px);white-space: normal">{{getCustomerLongName(props.row, schema.key)}}</div>
            <div class="dummy_input" :class="[schema.key, {readonly: props.row.editableFlg !== EDITABLE_FLG.ON, invalid: !props.row[`${schema.key}SectionId`]}]">
              <span class="customerName" style="display:flex;align-items:center" :content="getCustomerName(props.row, schema.key)"><figure class="avatar table"><img :src="getCustomerIcon(props.row, schema.key)"></figure></span>
            </div>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'Delete'"
        :key="schema.key"
        prop="Delete"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
      >
        <template slot-scope="props">
          <img v-if="canDeleteProcess(props.row) && (mode !== 'editFlow' || (mode === 'editFlow' && current))" class="close_times" src="@/assets/images/icons/times.svg">
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="schema.dataType === 'Blank'"
        :key="`${schema.dataType}_${index}`"
        :width="1"
        class-name="blank"
      />
      <el-table-column
        v-else-if="schema.dataType === 'SelectCompanyRole'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        class-name="no_padding select_role"
      >
        <template slot-scope="props">
          <el-form-item label="Company Role" :prop="schema.key">
            <el-select v-model="props.row[schema.key]" clearable @change="selectCompanyRole({row: props.row, key: schema.key})" :class="{ from_role: schema.key === 'fromRoleId', to_role: schema.key === 'toRoleId', invalid: !props.row[`${schema.key}`] }">
              <el-option
                v-for="role in getCompanyRoleCdVariablesOptions(props.row, schema.key)"
                :key="role.id"
                :label="role.label"
                :value="role.code">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <!-- PB-271 フロー編集時のプロセスメモ編集 -->
      <el-table-column
      v-else-if="schema.dataType === 'ProcessUserMemo' && mode == 'editFlow'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        class-name="process_user_memo"
      >
        <template slot-scope="props">
          <el-form-item v-if="editableTradingFlowIds && editableTradingFlowIds.includes(tradingFlowId)">
            <el-input
              v-model="props.row[schema.key]"
              @change="$emit('update-process-user-memo', props.row)"
              show-word-limit
              maxlength="100"
            ></el-input>
          </el-form-item>
          <div v-else>
            {{ props.row[schema.key] }}
          </div>
        </template>
      </el-table-column>
      <!-- PB-271 すべてのフロー編集時、新規登録時のプロセスメモ編集 -->
      <el-table-column
      v-else-if="schema.dataType === 'ProcessUserMemo' && (mode == 'new' || mode == 'edit')"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        class-name="process_user_memo"
      >
        <template slot-scope="props">
          <el-form-item>
            <el-input
              v-model="props.row[schema.key]"
              @change="$emit('update-process-user-memo', props.row)"
              show-word-limit
              maxlength="100"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <!-- PB-271-B-1 プロセスメモ用にカラム追加-->
      <el-table-column
        v-else-if="schema.dataType === 'ProcessUserMemo'"
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        class-name="process_user_memo"
      />
      <el-table-column
        v-else
        :key="schema.key"
        :prop="schema.key"
        :label="getLabel(schema.label)"
        :width="schema.width"
        :align="schema.align"
        :type="schema.dataType"
        :formatter="formatter"
        :fixed="schema.fixed"
        :render-header="renderHeader"
        :class-name="(schema.key === 'no' ? 'no ' : '') + (schema.bold ? 'bold' : '')"
        show-overflow-tooltip
      />
    </template>

    <!-- <el-table-column fixed="right" label="操作" width="80">
      <template slot-scope="scope">
        <el-button
          @click.native.prevent="deleteRow(scope.$index, items)"
          data-type="text"
          size="small"
        >
          削除
        </el-button>
      </template>
    </el-table-column> -->
  </el-table>
</template>

<script>
import mixinTable from '@/utils/mixinTable.js';
import { DETAIL_VIEWABLE_FLG } from 'lib-tw-common';

export default {
  name: 'TwTable2',
  mixins: [mixinTable],
  props: {
    current: Boolean,
    isOtherTrading: Boolean,
    tradingFlowId: String,
    editableTradingFlowIds: Array,
  },
  computed: {
    canDeleteProcess() {
      return row => {
        // 別取引の場合はプロセスの削除不可
        if (this.isOtherTrading) {
          return false;
        }
        if (this.isDeletableFlow) {
          return row.deletableFlg === this.DELETE_ABLE_FLG.OK;
        } else {
          // 削除不可のフロー（サブフロー等）ではプロセス数を0にはさせない
          return row.deletableFlg === this.DELETE_ABLE_FLG.OK && this.items.length > 1;
        }
      };
    }
  },
  methods: {
    cellClick(row, column, cell, event) {
      if (this.mode === 'editFlow' && !this.current) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
      if (this.mode && row.editableFlg === this.EDITABLE_FLG.ON && ['from', 'to'].includes(column.property)) {
        event.preventDefault();
        event.stopPropagation();
        this.$emit('select-section', row, column.property)
      } else if (column.property === "Delete" && event.target.tagName.toLowerCase() === 'img') {
        event.preventDefault();
        event.stopPropagation();
        this.$emit('remove-process', row);
      } else if (column.property === "DeleteEntity" && event.target.tagName.toLowerCase() === 'img') {
        event.preventDefault();
        event.stopPropagation();
        this.$emit('remove-entity', row);
      }
    },
    // eslint-disable-next-line no-unused-vars
    isDisabled({row, rowIndex}) {
      if (this.mode === 'editFlow' && !this.current) {
        return 'disabled';
      }
      if (this.mode) {
        return undefined;
      }

      // if (row.visibleFlg === VISIBLE_FLG.OFF) {
      //   return 'disabled';
      // }

      if (row.detailViewableFlg === DETAIL_VIEWABLE_FLG.OFF) {
        return 'disabled';
      }

      if (!row.fromSectionId || !row.toSectionId) {
        return 'disabled';
      }
      return undefined;
    },
    // Save Pattern カンパニーロール変更
    selectCompanyRole({row, key}) {
      this.$emit('select-company-role-change', {row, key});
    },
  }
};
</script>

<style lang='scss'>
// PB-271-B-1
// プロセスメモのみ text-overflow: ellipsis を無効化して全文表示する
.tw_table td .process_user_memo {
  .cell {
    text-overflow: unset;
    white-space: normal;
    overflow-wrap: anywhere;
  }
  .el-form-item {
    margin-bottom: 0px;
  }
}
</style>
