<template>
  <div class="tw_status_icon" :class="classes">
    <i/>
  </div>
</template>

<script>
import _ from 'lodash';
import { PROCESS_PROGRESS_STATUS, PROCESS_PROGRESS_STATUS_VARIABLES } from 'lib-tw-common';

export default {
  name: 'TwStatusIcon',
  props: {
    statusCode: {
      type: Number,
      required: true
    },
    approval: Boolean
  },
  computed: {
    getLabel() {
      return _.get(_.find(PROCESS_PROGRESS_STATUS_VARIABLES, { code: this.statusCode }), 'label');
    },
    getStatusName() {
      return (_.findKey(PROCESS_PROGRESS_STATUS, n => n === this.statusCode) || 'disabled').toLowerCase();
    },
    classes() {
      return {
        [`${this.getStatusName}`]: true,
        'approval': this.approval
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .tw_status_icon {
    font-size: 0;
    line-height: 1;

    i {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: no-repeat center;
      background-size: cover;
      margin-right: 4px;
    }

    // 承認プロセス
    &.approval.not_started {
      i { background-image: url(../../assets/images/statuses/not_started.svg); }
    }

    &.approval.f_input_in_progress, &.approval.t_input_in_progress, &.approval.not_closed {
      i { background-image: url(../../assets/images/statuses/input_in_progress.svg); }
    }

    &.approval.f_awaiting_approval {
      i { background-image: url(../../assets/images/statuses/awaiting_approval_from.svg); }
    }

    &.approval.t_awaiting_approval {
      i { background-image: url(../../assets/images/statuses/awaiting_approval_to.svg); }
    }

    &.approval.t_awaiting_confirmation {
      i { background-image: url(../../assets/images/statuses/awaiting_confirmation_to.svg); }
    }

    &.approval.agreed, &.approval.done {
      i { background-image: url(../../assets/images/statuses/done.svg); }
    }

    // 非承認プロセス
    &.not_started {
      i { background-image: url(../../assets/images/statuses/not_started.svg); }
    }

    &.f_input_in_progress {
      i { background-image: url(../../assets/images/statuses/input_in_progress.svg); }
    }

    &.t_awaiting_confirmation {
      i { background-image: url(../../assets/images/statuses/awaiting_confirmation_to.svg); }
    }

    &.done {
      i { background-image: url(../../assets/images/statuses/done.svg); }
    }
  }

// 取引データマップ
.data_map_card {
  &:hover {
    .approval.not_started {
      i { background-image: url(../../assets/images/statuses/not_started_white.svg); }
    }
    .approval.f_input_in_progress, &.approval.t_input_in_progress, &.approval.not_closed {
      i { background-image: url(../../assets/images/statuses/input_in_progress_white.svg); }
    }
    .approval.f_awaiting_approval {
      i { background-image: url(../../assets/images/statuses/awaiting_approval_from_white.svg); }
    }
    .approval.t_awaiting_approval {
      i { background-image: url(../../assets/images/statuses/awaiting_approval_to_white.svg); }
    }
    .approval.t_awaiting_confirmation {
      i { background-image: url(../../assets/images/statuses/awaiting_confirmation_to_white.svg); }
    }
    .approval.agreed, .approval.done {
      i { background-image: url(../../assets/images/statuses/done_white.svg); }
    }
  }
}
</style>
