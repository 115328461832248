<template>
  <div class="tw_status_bar" :class="classes">
    <div class="top"><i/>{{getLabel}}</div>
    <div class="bottom" />
  </div>
</template>

<script>
import _ from 'lodash';
import { PROCESS_PROGRESS_STATUS, PROCESS_PROGRESS_STATUS_VARIABLES } from 'lib-tw-common';

export default {
  name: 'TwStatusBar',
  props: {
    statusCode: {
      type: Number,
      required: true
    },
    approval: Boolean
  },
  computed: {
    getLabel() {
      if (this.statusCode === PROCESS_PROGRESS_STATUS.AGREED) {
        return 'Done';
      }
      return _.get(_.find(PROCESS_PROGRESS_STATUS_VARIABLES, { code: this.statusCode }), 'label');
    },
    getStatusName() {
      return (_.findKey(PROCESS_PROGRESS_STATUS, n => n === this.statusCode) || 'disabled').toLowerCase();
    },
    classes() {
      return {
        [`${this.getStatusName}`]: true,
        'approval': this.approval
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .tw_status_bar {
    .top {
      display: flex;
      font-size: 14px;
      line-height: 20px;
      color: $color_black;
      margin-bottom: 12px;

      i {
        flex-shrink: 0;
        display: block;
        width: 20px;
        height: 20px;
        background: no-repeat center;
        background-size: cover;
        margin-right: 4px;
      }
    }

    .bottom {
      width: 118px;
      height: 4px;
      background: url(../../assets/images/statuses/status_bar.png) no-repeat 0 0;
      background-size: 100% auto;
    }

    &.disabled .bottom {
      background: none;
    }

    // 承認プロセス
    &.approval.not_started {
      i { background-image: url(../../assets/images/statuses/not_started.svg); }
      .bottom { background-position: 0 0; }
    }

    &.approval.f_input_in_progress, &.approval.t_input_in_progress, &.approval.not_closed {
      i { background-image: url(../../assets/images/statuses/input_in_progress.svg); }
      .bottom { background-position: 0 -8px; }
    }

    &.approval.f_awaiting_approval {
      i { background-image: url(../../assets/images/statuses/awaiting_approval_from.svg); }
      .bottom { background-position: 0 -16px; }
    }

    &.approval.t_awaiting_confirmation {
      i { background-image: url(../../assets/images/statuses/awaiting_confirmation_to.svg); }
      .bottom { background-position: 0 -24px; }
    }

    &.approval.t_awaiting_approval {
      i { background-image: url(../../assets/images/statuses/awaiting_approval_to.svg); }
      .bottom { background-position: 0 -32px; }
    }

    &.approval.agreed, &.approval.done {
      i { background-image: url(../../assets/images/statuses/done.svg); }
      .bottom { background-position: 0 -40px; }
    }

    // 非承認プロセス
    &.not_started {
      i { background-image: url(../../assets/images/statuses/not_started.svg); }
      .bottom { background-position: 0 -48px; }
    }

    &.f_input_in_progress, &.t_input_in_progress, &.not_closed {
      i { background-image: url(../../assets/images/statuses/input_in_progress.svg); }
      .bottom { background-position: 0 -56px; }
    }

    &.t_awaiting_confirmation {
      i { background-image: url(../../assets/images/statuses/awaiting_confirmation_to.svg); }
      .bottom { background-position: 0 -64px; }
    }

    &.done, &.agreed {
      i { background-image: url(../../assets/images/statuses/done.svg); }
      .bottom { background-position: 0 100%; }
    }
  }
</style>
