import _ from 'lodash';
import dayjs from 'dayjs';
import {
  AUTHORIZATION_STATUS_VARIABLES,
  DELIVERY_TERMS_VARIABLES,
  GOODS_ATTR_ID,
  PAYMENT_TERMS_VARIABLES,
  TRADE_TYPE_VARIABLES,
  VISIBLE_FLG,
  PROCESS_CANCEL_FLG,
  SUBORDINATE_FLOW_FLG,
  MODE_OF_TRANSPORT_VARIABLES,
  TYPE_OF_SERVICE_VARIABLES,
  TYPE_OF_SERVICE_FCL_LCL_VARIABLES,
  EDITABLE_FLG,
  DELETE_ABLE_FLG,
  DELETE_FLG,
  IP_DN_TYPE_VARIABLES,
  DETAIL_VIEWABLE_FLG,
  PRIORITY_FLG,
  NEWS_TYPE_VARIABLES,
  INFORMATIONS_PUBLISHED_STATUS_VARIABLES,
  INFORMATIONS_PUBLISHED_FLG,
  COMPANY_ROLE_CD_VARIABLES_OPTIONS,
  TRADE_TYPE_DISPLAY_VARIABLES,
  LATEST_FLG,
  OTHER_TRADING_FLG,
  IP_REQUEST_STATUS_VARIABLES,
  IP_REQUEST_TYPE_VARIABLES
} from 'lib-tw-common';
import { entityNames, processNames, processIds } from '@/dictionaries/map.js';
import { formatQuantity, formatCurrency, formatNumberString, formatUtcDate } from '@/utils/searchUtil.js';
import TwStatusBar from '@/components/atoms/TwStatusBar';
import TwStatusIcon from '@/components/atoms/TwStatusIcon';
import TwStatusLabel from '@/components/atoms/TwStatusLabel';
import TwAlertIcon from '@/components/atoms/TwAlertIcon';
import TwEntityIcon from '@/components/atoms/TwEntityIcon';
import TwEntityStatusIcon from '@/components/atoms/TwEntityStatusIcon';
import TwTradingStatusIcon from '@/components/atoms/TwTradingStatusIcon';
import TwDropDownCatalog from '@/components/atoms/TwDropDownCatalog';
import { getProcessNumber } from './entity';

let time = null;

export default {
  props: {
    schemas: Array,
    items: Array,
    selection: Boolean,
    expand: Boolean,
    sortProp: String,
    sortOrder: String,
    rowKey: [String, Function],
    height: [String, Number],
    processSchemas: [Object, Array],
    processTable: Boolean,
    mode: String,
    highlightCurrentRow: Boolean,
    tradingId: String,
    hasError: Array,
    defaultExpandAll: Boolean,
    isDeletableFlow: Boolean,
    flowNameRule: Array,
    actions: Array,
    actionsMenu: Array,
    processList: Array,
    editableTradingFlowIds: Array,
  },
  components: {
    TwStatusBar,
    TwStatusIcon,
    TwStatusLabel,
    TwAlertIcon,
    TwEntityIcon,
    TwEntityStatusIcon,
    TwTradingStatusIcon,
    TwDropDownCatalog,
  },
  data() {
    return {
      multipleSelection: [],
      dbClick: false,
      maskStr: '*Undisclosed*', // 伏字文字列
      entityNames,
      showProgressDetail: false,
      PROCESS_CANCEL_FLG,
      EDITABLE_FLG,
      DELETE_ABLE_FLG,
      DELETE_FLG,
      VISIBLE_FLG,
      DETAIL_VIEWABLE_FLG,
      PRIORITY_FLG,
      INFORMATIONS_PUBLISHED_FLG,
      COMPANY_ROLE_CD_VARIABLES_OPTIONS,
      OTHER_TRADING_FLG,
    };
  },
  computed: {
    sortCaretAsc() {
      return prop => {
        if (this.sortProp === prop && this.sortOrder === 'asc') {
          return 'sort-caret ascending active';
        }
        return 'sort-caret ascending';
      };
    },
    sortCaretDesc() {
      return prop => {
        if (this.sortProp === prop && this.sortOrder === 'desc') {
          return 'sort-caret descending active';
        }
        return 'sort-caret descending';
      };
    },
    // セクション
    getCustomerName() {
      return (row, key) => {
        const lowerKey = key.toLowerCase();
        return _.get(row[key], `${lowerKey}CompanyShortName`) || _.get(row, `${lowerKey}CompanyShortName`) || (_.get(row[key], `${lowerKey}SectionName`) || _.get(row, `${lowerKey}SectionName`));
      };
    },
    getCustomerLongName() {
      return (row, key) => {
        const lowerKey = key.toLowerCase();
        const name = (_.get(row[key], `${lowerKey}CompanyName`) || _.get(row, `${lowerKey}CompanyName`) || _.get(row, `${lowerKey}CompanyNameEn`) || '') + ' ' + (_.get(row[key], `${lowerKey}SectionName`) || _.get(row, `${lowerKey}SectionName`) || '');
        return name === ' ' ? '' : name;
      };
    },
    getCustomerIcon() {
      return (row, key) => {
        const lowerKey = key.toLowerCase();
        return _.get(row[key], `${lowerKey}CompanyIcon`) || _.get(row, `${lowerKey}CompanyIcon`) || '/images/customer.png';
      };
    },
    // ユーザー
    getUserName() {
      return (row, key) => {
        return _.get(row, `${key}UserName`) || _.get(row, `userName${_.capitalize(key)}`);
      };
    },
    getUserIcon() {
      return (row, key) => {
        return _.get(row, `${key}UseIcon`) || _.get(row, `${key}UserIcon`) || _.get(row, `userIcon${_.capitalize(key)}`) || '/images/avatar.png';
      };
    },
    isShipper() {
      return this.$store.getters.isShipper;
    },
    getEntityProgressStatusCd() {
      return (entityStatus, entityTypeCd) => {
        return _.get(_.find(entityStatus, { entityTypeCd: entityTypeCd }), 'entityProgressStatusCd');
      };
    }
  },
  mounted() {
    const table = this.$el.getElementsByTagName('table');
    for (const el of table) {
      el.style.tableLayout = 'auto';
    }
    setTimeout(() => {
      for (const el of table) {
        el.style.tableLayout = 'fixed';
      }
    });
  },
  methods: {
    deleteRow(index, rows) {
      this.$confirm(`${rows[index].id}を削除します。<br>よろしいですか？`, '', {
        confirmButtonText: 'OK',
        cancelButtonText: 'キャンセル',
        dangerouslyUseHTMLString: true
      })
        .then(() => {
          rows.splice(index, 1);
        })
        .catch(() => {});
    },
    formatter(row, column, value) {
      if (column.type === 'Date') {
        // 入力した日付で表示
        if (value && dayjs(value).isValid()) {
          return dayjs(value).format(_.get(_.find(this.schemas, { key: column.property }), 'formatString') || 'YYYY-MM-DD');
        } else {
          return '';
        }
      }
      if (column.type === 'YYYYMMDDDate') {
        // 入力した日付で表示
        if (!value) {
          return '';
        }
        value = String(value);
        if (dayjs(value).isValid()) {
          return dayjs(value).format(_.get(_.find(this.schemas, { key: column.property }), 'formatString') || 'YYYY-MM-DD');
        } else {
          return '';
        }
      }
      if (column.type === 'YYMMDDDate') {
        // 入力した日付で表示
        if (!value) {
          return '';
        }
        value = `20${value}`;
        if (dayjs(value).isValid()) {
          return dayjs(value).format(_.get(_.find(this.schemas, { key: column.property }), 'formatString') || 'YYYY-MM-DD');
        } else {
          return '';
        }
      }
      if (column.type === 'DateTime') {
        // 入力した日付時刻で表示
        if (value && dayjs(value).isValid()) {
          return dayjs(value).format(_.get(_.find(this.schemas, { key: column.property }), 'formatString') || 'YYYY-MM-DD HH:mm');
        } else {
          return '';
        }
      }
      if (column.type === 'DateTimeSeconds') {
        // 入力した日付時刻で表示（秒まで）
        if (value && dayjs(value).isValid()) {
          return dayjs(value).format(_.get(_.find(this.schemas, { key: column.property }), 'formatString') || 'YYYY-MM-DD HH:mm:ss');
        } else {
          return '';
        }
      }
      if (column.type === 'SystemDate') {
        // システム日付
        if (value && dayjs(value).isValid()) {
          return dayjs(value)
            .utc()
            .local()
            .format(_.get(_.find(this.schemas, { key: column.property }), 'formatString') || 'YYYY-MM-DD');
        } else {
          return '';
        }
      }
      if (column.type === 'Today') {
        if (value && dayjs(value).isValid()) {
          return dayjs(value).format(
            dayjs(value)
              .utc()
              .local()
              .isToday()
              ? 'HH:mm'
              : 'MM-DD'
          );
        } else {
          return '';
        }
      }
      if (column.type === 'TradingFlowId') {
        if (!row.tradingId && !this.tradingId && row.tradingFlowId) {
          return row.tradingFlowId;
        }
        if (row.tradingId || this.tradingId || row.tradingFlowId) {
          return `${row.otherTradingId || row.tradingId || this.tradingId || ''}/${row.tradingFlowId || ''}`;
        } else return '';
      }
      if (column.type === 'TradeTypeCd') {
        return _.get(_.find(TRADE_TYPE_VARIABLES, { code: row[column.property] }), 'label');
      }
      if (column.type === 'TradeTypeCdDisplay') {
        return _.get(_.find(TRADE_TYPE_DISPLAY_VARIABLES, { code: row[column.property] }), 'label');
      }
      if (column.type === 'PaymentTermsCd') {
        const code = row.paymentTermsCd || row[column.property];
        return _.get(_.find(PAYMENT_TERMS_VARIABLES, { code: code }), 'label');
      }
      if (column.type === 'DeliveryTermsCd') {
        const code = row.deliveryTermsCd || row[column.property];
        return _.get(_.find(DELIVERY_TERMS_VARIABLES, { code: code }), 'label');
      }
      if (column.type === 'ApprovalStatusCd') {
        return _.get(_.find(AUTHORIZATION_STATUS_VARIABLES, { code: row.approvalStatus }), 'label');
      }
      if (column.type === 'ModeOfTransport') {
        return _.get(_.find(MODE_OF_TRANSPORT_VARIABLES, { code: row[column.property] }), 'label');
      }
      if (column.type === 'TypeOfService') {
        return _.get(_.find(TYPE_OF_SERVICE_VARIABLES, { code: row[column.property] }), 'label');
      }
      if (column.type === 'TypeOfServiceFclLcl') {
        return _.get(_.find(TYPE_OF_SERVICE_FCL_LCL_VARIABLES, { code: row[column.property] }), 'label');
      }
      if (column.type === 'Currency') {
        const target = _.get(_.find(this.schemas, { key: column.property }), 'target');

        if (!target || !row[target] || !this.$store.state.systemProperties.SYS_MST_CURRENCY) {
          return formatCurrency(value);
        }
        const rule = _.find(this.$store.getters.getSysMstCurrency, { alphabeticCode: row[target] });
        if (!rule) {
          return formatCurrency(value);
        }
        // 小数点以下は通貨の指定桁で切り捨て0埋め
        return formatCurrency(value, rule.symbol, rule.minorUnit);
      }
      if (column.type === 'Quantity') {
        const target = _.get(_.find(this.schemas, { key: column.property }), 'target');
        return formatQuantity(value, row[target]);
      }
      // 商品梱包 容積計
      if (column.type === 'MeasurementTotal') {
        if (!_.every([row.length, row.width, row.height], _.isNumber)) {
          // L, W, Hが数値じゃない場合は空文字を返却
          return '';
        }
        const target = _.get(_.find(this.schemas, { key: column.property }), 'target');
        const result = row.length * row.width * row.height;
        return formatQuantity(result, row[target]);
      }
      if (column.type === 'CompanyRoleCd') {
        if (_.isArray(value) && value[0]) {
          return _.filter(_.values(value[0]).join(', '));
        } else {
          return '';
        }
      }
      // HSコード
      if (column.type === 'HsCode') {
        return _.map(
          _.filter(value, o => {
            return o.attrId === GOODS_ATTR_ID.HS_CODE && o.stringVal;
          }),
          o => o.stringVal
        ).join('/');
      }
      // No エンティティが契約まではContract No 契約以降はInvoiceNoを表示
      if (column.type === 'DisplayNo') {
        return row.imCustomInvoiceNo || row.exCustomInvoiceNo || row.invoiceNo || row.contractNo || '';
      }

      // ファイルサイズ
      if (column.type === 'FileSize') {
        return this.$options.filters.fileSize(value);
      }

      // 残数量
      if (column.type === 'RemainingQuantity') {
        const target = _.get(_.find(this.schemas, { key: column.property }), 'target');
        const remaining = _.get(row, column.property) || '';
        const total = _.get(row, target) || '';
        if (remaining || total) {
          return `${formatNumberString(remaining)}/${formatNumberString(total)}`;
        } else {
          return '';
        }
      }

      if (column.type === 'NumberString') {
        return formatNumberString(value);
      }

      if (column.type === 'Entity') {
        if (row.processId) {
          return this.$t(`Entity.${this.getEntityNameByProcessId(row.processId)}`);
        } else {
          return value;
        }
      }

      if (column.type === 'Process' || column.type === 'ProcessLinkColor') {
        if (row.processId) {
          if (row.processId === 'TPARB' || row.processId === 'TPRB') {
            return this.$t('Process.TPATPRB');
          }
          return this.$t(`Process.${row.processId.replace(/\d/g, '')}`) + getProcessNumber(row.processId);
        } else if (row.processType) {
          return this.$t(`Process.${row.processType.replace('PT_', '').toUpperCase()}`);
        } else {
          return value;
        }
      }

      // IP, DN ドキュメントタイプ
      if (column.type === 'DocType') {
        return _.get(_.find([{ code: 'IP', label: 'I/P' }, { code: 'DN', label: 'D/N' }], { code: row[column.property] }), 'label');
      }
      // IP, DNタイプ
      if (column.type === 'IpDnType') {
        return _.get(_.find(IP_DN_TYPE_VARIABLES, { code: value }), 'label', '');
      }

      // IP Request Status
      if (column.type === 'IpreqStatus') {
        return _.get(_.find(IP_REQUEST_STATUS_VARIABLES, { code: value }), 'label', '');
      }
      // IP Request Type
      if (column.type === 'IpRequestType') {
        return _.get(_.find(IP_REQUEST_TYPE_VARIABLES, { code: value }), 'label', '');
      }

      // 配列文字列（LCのmessageTypeなど）
      if (column.type === 'ArrayString') {
        if (_.isArray(value)) {
          return value.join(',');
        }
        return '';
      }

      if (column.type === 'TransitDays') {
        if (_.isNumber(value)) {
          return `${value} Days`;
        }
        return value;
      }

      return _.get(row, column.property);
    },
    renderHeader(h, e) {
      return h('div', null, [
        e.column.label,
        h('span', { class: 'caret-wrapper' }, [
          h(
            'i',
            {
              class: this.sortCaretAsc(e.column.property)
            },
            null
          ),
          h(
            'i',
            {
              class: this.sortCaretDesc(e.column.property)
            },
            null
          )
        ])
      ]);
    },
    renderExpandHeader(h) {
      return h('div', null, ['>']);
    },
    sortBackground({ column }) {
      if (this.sortProp === column.property && this.sortOrder) {
        return 'sort';
      } else if (_.get(_.find(this.schemas, { key: column.property }), 'sortable')) {
        return ' is-sortable';
      } else {
        return 'normal';
      }
    },
    sortChange(column) {
      if (_.get(_.find(this.schemas, { key: column.property }), 'sortable')) {
        this.$emit('sort-change', column);
      }
    },
    selectionChange(multipleSelection) {
      this.$emit('selection-change', multipleSelection);
    },
    // eslint-disable-next-line no-unused-vars
    rowClassName({ row, rowIndex }) {
      let className = '';
      // 取引管理画面でflowのvisibleFlgがオフの時は非表示
      if (this.flowNameRule && row.visibleFlg === VISIBLE_FLG.OFF) {
        return 'hidden_flow';
      }
      if (row.otherTradingFlg === OTHER_TRADING_FLG.ON) {
        return 'other_trading';
      }
      if (this.mode === 'editFlow' && this.editableTradingFlowIds.includes(row.tradingFlowId)) {
        className = 'current_flow';
      }
      if (!this.mode && _.isEmpty(this.hasError)) {
        return false;
      }

      if (this.mode === 'POOL') {
        if (_.get(row, 'poolSelected')) {
          // プール一覧で選択済みの場合にdisabledクラスを付与
          className = 'pool_selected';
        } else if (_.get(row, 'alertMsg')) {
          // プール一覧でアラートがある場合にpool_alertクラスを付与
          className = 'pool_alert';
        }
        if(_.get(row, 'latestFlg') === LATEST_FLG.OFF) {
          // プール一覧でlatestFlgがoffの場合にクラスを付与
          className += ' pool_not_latest';
        }
      }

      if (this.hasError && this.hasError.length) {
        return _.some(this.hasError, e => {
          return e.startsWith(row.goodsLineProps) || e.startsWith(row.goodsLinePriceProps) || _.some(row.goodsLineTypeProps, p => e.startsWith(p)) || _.some(row.goodsLinePackingProps, p => e.startsWith(p));
        })
          ? 'invalid'
          : false;
      }

      const hasInvalid = _.some(row.entityStatus, entity => {
        return _.some(entity.processStatus, process => {
          if (process.visibleFlg === VISIBLE_FLG.OFF) {
            return false;
          }
          return !process.fromSectionId || !process.toSectionId;
        });
      });
      return hasInvalid ? className + 'invalid' : className ? className : false;
    },
    rowClick(row, column, event) {
      clearTimeout(time);
      if (window.getSelection().toString()) {
        event.preventDefault();
        return;
      }
      // 商品モーダルで添付ファイルがある場合は無視
      if (column && column.type === 'Catalog' && row.files.length) {
        event.preventDefault();
        return;
      }
      time = setTimeout(() => {
        this.$emit('row-click', row, column, event);
      }, 300);
    },
    rowDbClick(row) {
      clearTimeout(time);
      this.$emit('row-dbclick', row);
    },
    processRowClick(row) {
      clearTimeout(time);
      time = setTimeout(() => {
        this.$emit('process-row-click', row);
      }, 300);
    },
    processRowDbClick(row) {
      clearTimeout(time);
      this.$emit('process-row-dbclick', row);
    },
    handleCurrentChange(row, oldRow) {
      this.$emit('current-change', row, oldRow);
    },
    // 取引一覧のプロセス進捗ステータス表示用の配列を生成します
    flatProgressStatus(entityList) {
      const flatArray = _.reduce(
        entityList,
        (res, o) => {
          res = res.concat(o.processStatus);
          return res;
        },
        []
      );

      return _.map(processIds, id => {
        return _.find(flatArray, { processId: id }) || { processId: id };
      });
    },
    getProgressApproval(processId) {
      return _.get(processNames[processId.replace(/\d/g, '')], 'approval') || false;
    },
    getParent(processId) {
      return _.get(processNames[processId.replace(/\d/g, '')], 'parent');
    },
    getEntityId(entityTypeCd) {
      return _.get(_.find(entityNames, { code: entityTypeCd }), 'entityId');
    },
    getEntityName(entityTypeCd) {
      return _.get(_.find(entityNames, { code: entityTypeCd }), 'nameEn');
    },
    getEntityNameByProcessId(processId) {
      if (!this.getParent(processId)) {
        return null;
      }
      return entityNames[this.getParent(processId)].nameEn;
    },
    // プロセスの省略名を取得します
    getProcessExShortName(processId) {
      return _.get(processNames[processId.replace(/\d/g, '')], 'nameExShort') + getProcessNumber(processId);
    },
    // プロセス詳細へのリンクを返却します
    getProcessPagePrams(row) {
      return {
        name: `Process${_.capitalize(row.processId.replace(/\d/g, ''))}`,
        params: { entityId: row.entityId, processSeq: row.processSeq, _processId: row.processId.toLowerCase() }
      };
    },
    // メール詳細へのリンクを返却します
    getMailPagePrams(row) {
      const offset = _.findIndex(this.items, { noticeHistoryId: row.noticeHistoryId }) - 1;

      return {
        name: 'MailDetail',
        params: { noticeHistoryId: row.noticeHistoryId },
        query: {
          offset: offset,
          start: formatUtcDate(
            dayjs()
              .subtract(30, 'day')
              .toDate()
          ),
          end: formatUtcDate(new Date()),
          initial: 0
        }
      };
    },
    // 取引管理へのリンクを返却します
    getTradingPagePrams(row) {
      return {
        name: 'ManageTrading',
        params: { tradingId: row.tradingId }
      };
    },
    // 商品カタログダウンロード
    selectFile(file) {
      this.$emit('select-file', file);
    },
    // 取引管理のフローのメインかサブを返却します
    getFlowLabel(obj) {
      if (!obj) {
        return '';
      }
      if (obj.subFlg === SUBORDINATE_FLOW_FLG.ON) {
        return 'S';
      }
      if (obj.parentFlg === SUBORDINATE_FLOW_FLG.ON) {
        return 'M';
      }
      return '';
    },
    // 多言語対応したラベルを返却します
    getLabel(label) {
      if (this.$te(`List.${label}`)) {
        return this.$t(`List.${label}`);
      }
      return label;
    },
    // rowの選択状態をセットします
    setCurrentRow(row) {
      this.$refs.table.setCurrentRow(row);
    },
    sanitize(str) {
      return this.$options.filters.newlines(str);
    },
    // LCプール一覧 確認銀行A or Dを返却します
    getRequestedConfirmationPartyOption(row) {
      return _.get(row, 'requestedConfirmationPartyOptionA') || _.get(row, 'requestedConfirmationPartyOptionD', '');
    },
    getDestinations(ary) {
      if (ary.length > 3) {
        return _.map(ary.slice(0, 3), 'userNameTo').join(', ') + ', …';
      }
      return _.map(ary, 'userNameTo').join(', ');
    },
    // newsTypeの表示名を取得します
    getNewsType(type) {
      return _.get(_.find(NEWS_TYPE_VARIABLES, { code: type }), 'label');
    },
    // newsTypeの表示名を取得します
    getNewsStatus(type) {
      return _.get(_.find(INFORMATIONS_PUBLISHED_STATUS_VARIABLES, { code: type }), 'label');
    },
    // プロセスリストから選択可能なカンパニーロールを返却します
    getCompanyRoleCdVariablesOptions(row, key) {
      const processList = _.get(this, 'processList');
      const fromTo = key === 'fromRoleId' ? 'from' : 'to';
      const targetProcess = _.find(processList, { processId: row.processId });
      if (targetProcess) {
        const roleList = targetProcess[`${fromTo}RoleList`];
        const companyRoleCdVariablesOptions = _.cloneDeep(COMPANY_ROLE_CD_VARIABLES_OPTIONS);
        return _.filter(companyRoleCdVariablesOptions, role => {
          return _.some(roleList, { [`${fromTo}CompanyRoleId`]: role.code });
        });
      } else {
        return COMPANY_ROLE_CD_VARIABLES_OPTIONS;
      }
    },
    // セルのテキストをコピーします
    onRowContextmenu(row, column, event) {
      event.preventDefault();
      let target = event.target;
      if (target.tagName.toUpperCase !== 'TD') {
        // イベントターゲットがTDではない場合は祖先のTDを取得
        target = target.closest('td');
      }
      console.log(target.innerText)
      this.$copyText(target.innerText).then(() => {
        this.$message({
          message: 'Copied!',
          type: 'success'
        });
      }, () => {});
    }
  }
};
