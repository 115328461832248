<template>
  <div class="tw_status_label" :class="classes" :style="style">{{getLabel}}</div>
</template>

<script>
import _ from 'lodash';
import { statusLabels } from '@/dictionaries/status.js';


export default {
  name: 'TwStatusLabel',
  props: {
    statusName: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
  computed: {
    getLabel() {
      return _.get(statusLabels, `${this.statusName}.label`) || '';
    },
    classes() {
      return {
        [`tw_status_label__${this.size || 'medium'}`]: true,
      };
    },
    style() {
      return {
        color: _.get(statusLabels, `${this.statusName}.color`) || '',
        backgroundColor: _.get(statusLabels, `${this.statusName}.backgroundColor`) || '',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .tw_status_label {
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    flex-shrink: 0;

    &.tw_status_label__large {
      height: 29px;
      font-size: 14px;
      line-height: 20px;
      border-radius: 14.5px;
      padding: 0 9px;
    }

    &.tw_status_label__medium {
      height: 16px;
      font-size: 10px;
      line-height: 14px;
      border-radius: 8px;
      padding: 0 8px;
    }

    &.tw_status_label__small {
      height: 14px;
      font-size: 10px;
      line-height: 14px;
      border-radius: 7px;
      padding: 0 8px;
    }
  }
</style>
