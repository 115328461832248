<template>
  <div class="tw_entity_buttons">
    <tw-entity-icon v-for="entity in entityNames" :key="entity.entityId" :class="{current: isCurrentPage(entity)}" :entityName="entity.entityId" :popover="true" :size="size" color="color_shadow" @click="onClick"/>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { entityNames } from '@/dictionaries/map.js';
import TwEntityIcon from '@/components/atoms/TwEntityIcon';

export default {
  name: 'TwEntityButtons',
  props: {
    size: {
      type: String,
      default: 'ex_large'
    }
  },
  components: {
    TwEntityIcon
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(['canAccess']),
    entityNames() {
      return _.filter(entityNames, entity => {
        if (entity.entityId === 'TRANSPORTATION_AG') {
          // 輸出契約の時
          return this.canAccess('ListTransportationAgreement');
        }
        return this.canAccess(entity.pageName);
      });
    },
    isCurrentPage() {
      return entity => {
        return this.$route.name === entity.pageName;
      }
    },
  },
  methods: {
    onClick(entity) {
      if (this.isCurrentPage(entity)) {
        return false;
      }
      this.$router.push({ name: entity.pageName});
    }
  }
};
</script>

<style lang="scss">
  .tw_entity_buttons {
    position: absolute;
    top: 21px;
    right: 58px;
    display: flex;

    .tw_entity_icon {
      margin-left: 12px;
      margin-right: 0;

      &.current {
        cursor: default;

        &:hover i {
          opacity: 1;
        }
      }
    }
  }
</style>
