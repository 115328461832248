<template>
  <el-dropdown trigger="click" ref="dropdown">
    <img class="icon_file" src="@/assets/images/icons/icon_file.svg">
    <el-dropdown-menu class="catalog_dropdown" slot="dropdown" ref="menu">
      <el-dropdown-item v-for="(file, index) in files" :key="index" @click.native="selectFile(file)">{{file.filepathVal | fileName}}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'TwDropDownCatalog',
  props: {
    files: Array,
    goodsId: String,
  },
  data() {
    return {
      open: false,
    };
  },
  mounted() {
    this.$refs.menu.$el.addEventListener('mouseleave', this.hide, false);
  },
  beforeDestroy() {
    this.$refs.menu.$el.removeEventListener('mouseleave', this.hide, false);
  },
  methods: {
    selectFile(file) {
      this.$emit('select-file', {...file, goodsId: this.goodsId});
    },
    hide() {
      this.$refs.dropdown.hide();
    },
  }
};
</script>