<template>
  <div v-if="color" class="tw_alert_icon" :class="classes" />
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import { formatResponseDate } from '@/utils/searchUtil.js';
import { PROCESS_PROGRESS_STATUS } from 'lib-tw-common';

export default {
  name: 'TwAlertIcon',
  props: {
    limit: String,
    type: String,
    size: String,
    processProgressStatus: Number,
  },
  data() {
    return {
      now: dayjs.utc(),
    };
  },
  computed: {
    color() {
      if (!this.limit || _.isNaN(this.processProgressStatus)) {
        return '';
      }
      // 完了時は表示しない
      if ([PROCESS_PROGRESS_STATUS.AGREED, PROCESS_PROGRESS_STATUS.DONE].includes(this.processProgressStatus)) {
        return '';
      }
      const limit = dayjs(formatResponseDate(this.limit));
      if (limit.isToday() || limit.isTomorrow()) {
        return 'warn';
      }
      if (dayjs().isSameOrAfter(limit, 'day')) {
        return 'danger'
      }
      return '';
    },
    classes() {
      return {
        [`${this.type || 'line'}`]: true,
        [`${this.color}`]: true,
        [`${this.size}`]: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .tw_alert_icon {
    display: block;
    width: 20px;
    height: 20px;
    background: no-repeat center;
    background-size: cover;
    flex-shrink: 0;
    
    &.line.danger {
      background-image: url(../../assets/images/icons/icon_alert_danger.svg);
    }

    &.line.warn {
      background-image: url(../../assets/images/icons/icon_alert_warn.svg);
    }

    &.color {
      background-image: url(../../assets/images/icons/icon_alert.svg);
      background-size: 12px auto;
      border-radius: 100%;
      background-position: center 45%;
    }

    &.color.danger {
      background-color: $color_warning;
    }

    &.color.warn {
      background-color: $color_draft;
    }

    &.small {
      width: 12px;
      height: 12px;
      background-size: 8px auto;
      background-position: center;
    }
  }
</style>
