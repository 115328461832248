// eslint-disable-next-line import/prefer-default-export
export const statusLabels = {
  DRAFT: {
    label: 'Draft',
    color: '#FFFFFF',
    backgroundColor: '#FFC555',
    key: ''
  },
  NOT_STARTED: {
    label: 'Not Started',
    color: '#FFFFFF',
    backgroundColor: '#FFC555',
    key: ''
  },
  IN_PROGRESS: {
    label: 'In Progress',
    color: '#FFFFFF',
    backgroundColor: '#6DD0C2',
    key: ''
  },
  CLOSED: {
    label: 'Closed',
    color: '#3E3A39',
    backgroundColor: '#E7E7E7',
    key: ''
  },
  INVALID: {
    label: 'Invalid',
    color: '#3E3A39',
    backgroundColor: '#E7E7E7',
    key: ''
  },
  DONE: {
    label: 'Done',
    color: '#3E3A39',
    backgroundColor: '#E7E7E7',
    key: ''
  },
  CONTRACT: {
    label: 'Contract',
    color: '#FFFFFF',
    backgroundColor: '#6DD0C2',
    key: ''
  },
  LC: {
    label: 'L/C',
    color: '#FFFFFF',
    backgroundColor: '#6DD0C2',
    key: ''
  },
  EX_CUSTOMS: {
    label: 'EX Customs',
    color: '#FFFFFF',
    backgroundColor: '#6DD0C2',
    key: ''
  },
  MARINE_IP: {
    label: 'Marine I/P',
    color: '#FFFFFF',
    backgroundColor: '#6DD0C2',
    key: ''
  },
  TRANSPORTATION: {
    label: 'Transportation',
    color: '#FFFFFF',
    backgroundColor: '#6DD0C2',
    key: ''
  },
  FINAL_DOCS: {
    label: 'Final Docs',
    color: '#FFFFFF',
    backgroundColor: '#6DD0C2',
    key: ''
  },
  IM_CUSTOMS: {
    label: 'IM Customs',
    color: '#3E3A39',
    backgroundColor: '#E7E7E7',
    key: ''
  },
  TO_YOU: {
    label: 'To You',
    color: '#FFFFFF',
    backgroundColor: '#8E84F4',
    key: ''
  },
  SECTION_NAME: {
    label: 'Section name',
    color: '#FFFFFF',
    backgroundColor: '#EC30C9',
    key: ''
  },
  DELETE: {
    label: 'Delete',
    color: '#FFFFFF',
    backgroundColor: '#E00001',
    key: ''
  },
  REQUEST: {
    label: 'Request',
    color: '#FFFFFF',
    backgroundColor: '#8AC7FF',
    key: ''
  },
  ACCEPTED: {
    label: 'Accepted',
    color: '#FFFFFF',
    backgroundColor: '#13D17F',
    key: ''
  },
  APPROVER: {
    label: 'Approver',
    color: '#FFFFFF',
    backgroundColor: '#13D17F',
    key: ''
  },
  DEPUTY_APPROVER: {
    label: 'Deputy Approver',
    color: '#FFFFFF',
    backgroundColor: '#13D17F',
    key: ''
  },
  RETURN: {
    label: 'Return',
    color: '#FFFFFF',
    backgroundColor: '#FFA34E',
    key: ''
  },
  SIGNED: {
    label: 'Signed',
    color: '#FFFFFF',
    backgroundColor: '#FFA34E',
    key: ''
  },
  ISSUED: {
    label: 'Issued',
    color: '#FFFFFF',
    backgroundColor: '#6DD0C2',
    key: ''
  },
  EXPIRED: {
    label: 'Expired',
    color: '#FFFFFF',
    backgroundColor: '#8E84F4',
    key: ''
  },
  CANCELED: {
    label: 'Canceled',
    color: '#3E3A39',
    backgroundColor: '#E7E7E7',
    key: ''
  },
};
