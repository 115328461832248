<template>
  <div class="tw_pagination_container">
    <el-pagination
      class="tw_pagination"
      v-if="total > pageSize"
      layout="slot, prev, pager, next"
      prev-text="Prev"
      next-text="Next"
      :current-page.sync="pageNo"
      :total="total"
      :pageSize="pageSize"
      background
      @current-change="handleCurrentChange"
    >
      <span class="total">{{pageSize * (pageNo - 1) + 1}}-{{getTotal}} of {{total}}</span>
    </el-pagination>
    <span v-else class="total">{{pageSize * (pageNo - 1) + 1}}-{{getTotal}} of {{total}}</span>
  </div>
</template>

<script>
export default {
  name: 'TwPagination',
  props: {
    total: Number,
    currentPage: Number,
    pageSize: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      pageNo: this.currentPage,
    };
  },
  computed: {
    getTotal() {
      return Math.min(this.total, this.pageSize * this.pageNo)
    }
  },
  watch: {
    currentPage: function (val) {
      this.pageNo = val;
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit('onPaging', val);
    }
  }
};
</script>

<style lang="scss">
  .tw_pagination_container {
    margin: 12px 0;
  }
  .tw_pagination {
    display: flex;
    align-items:center;
    font-weight: normal;
    font-size: 12px;
    line-height: 28px;
    margin: 0;

    button:disabled, .el-pager li  {
      background: none;
    }

    span:not([class*=suffix]), button {
      font-size: 12px;
      color: $color_gray_800;
    }

    &.is-background {
      .btn-prev, .btn-next, .el-pager li {
        min-width: 20px;
        color: $color_gray_800;
        background-color: transparent;
        margin: 0 8px;
      }
    }

    .el-pager, .el-pager .more::before {
      line-height: 20px;
    }

    .el-pager li {
      font-size: 12px;
      border-radius: 10px!important;
      height: 20px;
      line-height: 20px;
      text-align: center;
    }

    .btn-prev, .btn-next {
      width: 61px;
      height: 26px;
      border: 1px solid $color_gray_400!important;
      box-sizing: border-box;
      border-radius: 13px!important;

      &:disabled {
        opacity: .5;
      }

      span:not([class*=suffix]) {
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        min-width: 0;
      }
    }

    .btn-prev, .btn-prev:disabled {
      padding-left: 24px!important;
      padding-right: 12px!important;
      background: url(../../assets/images/icons/chevron_left_gray800.svg) no-repeat 4px center;
      background-size: 12px auto;
    }

    .btn-next, .btn-next:disabled {
      padding-left: 12px!important;
      padding-right: 24px!important;
      background: url(../../assets/images/icons/chevron_right_gray800.svg) no-repeat calc(100% - 4px) center;
      background-size: 12px auto;
    }
  }

  .total {
    display: inline-block;
    margin-right: 8px;
    color: $color_black;
    font-size: 12px;
  }
  
</style>
