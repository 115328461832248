<template>
  <div class="tw_status_icon" :class="classes">
    <el-tooltip v-if="getLabel" placement="top" :popper-class="`mcu ${getStatusName}`" :tabindex="-1">
      <div slot="content">{{getLabel}}</div>
      <i/>
    </el-tooltip>
  </div>
</template>

<script>
import _ from 'lodash';
import { ENTITY_PROGRESS_STATUS_VARIABLES } from 'lib-tw-common';

export default {
  name: 'TwEntityStatusIcon',
  props: {
    statusCode: {
      type: Number,
      required: true
    },
  },
  computed: {
    getLabel() {
      return _.get(_.find(ENTITY_PROGRESS_STATUS_VARIABLES, { code: this.statusCode }), 'label');
    },
    getStatusName() {
      if (!this.getLabel) {
        return '';
      }
      return _.snakeCase(this.getLabel);
    },
    classes() {
      return {
        [`${this.getStatusName}`]: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .tw_status_icon {
    font-size: 0;
    line-height: 1;
    
    i {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: no-repeat center;
      background-size: cover;
      margin-right: 4px;
    }

    &.not_started {
      i { background-image: url(../../assets/images/statuses/not_started.svg); }
    }

    &.in_progress {
      i { background-image: url(../../assets/images/statuses/flow_in_progress.svg); }
    }

    &.closed {
      i { background-image: url(../../assets/images/statuses/done.svg); }
    }
  }
</style>
